:root {
  --bg: white;
  --fg: black;
  --mid: lightgrey;
  --accent: magenta;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: #242423;
    --fg: white;
    --mid: grey;
    --accent: magenta;
  }
}

body {
  background: var(--bg);
  color: var(--fg);
  padding: 5px;
  font-family: sans-serif;
  overflow: hidden;
}

#app {
  height: 100vh;
  margin-bottom: 50px;
  overflow-y: auto;
}

input {
  background: var(--mid);
  color: var(--fg);
  width: 100%;
  border: 1px solid #0000;
  border-radius: 5px;
  outline: none;
  padding: 10px;
}

input::placeholder {
  color: #a9a9a9;
}

:focus {
  border: 1px solid var(--accent);
  outline: none;
}

#intro {
  width: 100vw;
  height: calc(100vh - 90px);
  text-align: center;
  background: var(--bg);
  padding-top: 10vh;
  transition: top .5s;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

#intro img {
  width: 100px;
  height: 100px;
}

#intro p {
  opacity: .7;
  max-width: 80%;
  display: inline-block;
}

#intro button {
  opacity: .9;
  background: var(--mid);
  cursor: pointer;
  color: var(--fg);
  border: none;
  border-radius: 5px;
  padding: 10px;
  transition: opacity .3s;
}

#intro button:hover {
  opacity: 1;
}

#nosearch {
  text-align: center;
}

#library {
  text-align: left;
  margin-bottom: 150px;
}

#library details {
  padding-left: 30px;
}

#library details summary {
  margin-left: -30px;
}

#library details summary:focus {
  background: var(--accent);
}

#signin {
  padding-top: 40%;
}

sup {
  font-size: .5em;
}

#hints {
  border-radius: 5px;
  margin-top: 0;
  margin-left: 10px;
}

#hints .searchhint {
  color: var(--mid);
  cursor: pointer;
  list-style: none;
}

#hints .fullcolor {
  color: var(--fg);
}

.searchresult, summary {
  border-bottom: 1px solid var(--mid);
  cursor: pointer;
  padding: 10px;
  transition: background .2s;
  overflow: auto;
}

.searchresult:first-of-type, summary:first-of-type {
  border-top: 1px solid var(--mid);
}

.searchresult:hover, .searchresult:focus, summary:hover, summary:focus {
  background: var(--accent);
}

.searchresult {
  list-style: none;
}

.song img, .song .artwork {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  background: #a9a9a9;
  border-radius: 5px;
}

.song div {
  width: calc(100% - 100px);
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.song .time {
  color: var(--mid);
}

.song[disabled] {
  opacity: .3;
  pointer-events: none;
  cursor: not-allowed;
}

.dim {
  opacity: .5;
}

ul {
  padding: 0;
}

#results {
  margin-bottom: 50px;
}

#player {
  cursor: pointer;
  width: 100%;
  height: 50px;
  background: var(--mid);
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
}

#player #nowplaying {
  width: calc(100vw - 120px);
  float: left;
  display: inline-block;
}

#player #buttons {
  width: 100px;
  text-align: right;
  height: 100%;
  justify-content: right;
  align-items: center;
  display: flex;
}

#player #buttons button {
  color: var(--fg);
  background: none;
  border: none;
  border-radius: 100%;
  font-size: 1.5em;
  font-weight: bolder;
  transition: background 1s;
}

#player #buttons button:focus, #player #buttons button:hover {
  opacity: .3;
  background: #000;
}

.popup {
  color: #fff;
  opacity: .9;
  white-space: nowrap;
  background: #000c;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*# sourceMappingURL=index.179c1eec.css.map */
