// VARIABLES ========================================================
:root {
	--bg: white;
	--fg: black;
	--mid: lightgrey;
	--accent: magenta;
}
@media (prefers-color-scheme: dark) {:root{
	--bg: #242423;
	--fg: white;
	--mid:grey;
	--accent: magenta;
}}

// MAIN ELEMENTS =============================================================
body{
	background: var(--bg);
	color: var(--fg);
	font-family:sans-serif;
	padding: 5px;
	overflow:hidden;
}
#app {
	overflow-y: auto;
	height: 100vh;
	margin-bottom: 50px;
}
input {
	background: var(--mid);
	color: var(--fg);
	width: 100%;
	border-radius: 5px;
	padding: 10px;
	outline: none;
	border: 1px solid transparent;
	&::placeholder {color: darkgrey}
}
*:focus {
	border: 1px solid var(--accent);
	outline: none;
}
#intro {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(100vh - 90px);
	overflow-y: auto;
	text-align: center;
	background: var(--bg);
	transition: top 0.5s;
	padding-top: 10vh;
	img {
		width: 100px;
		height: 100px;
	}
	p {
		opacity: 70%;
		max-width: 80%;
		display:inline-block
	}
	button {
		border: none;
		padding: 10px;
		opacity: 90%;
		border-radius: 5px;
		background: var(--mid);
		cursor: pointer;
		color: var(--fg);
		transition: opacity 0.3s;
		&:hover {opacity:100%}
	}
}

// OTHER ======================================================================
#nosearch {text-align: center}
#library {
	margin-bottom: 150px;
	text-align: left;
	details {
		padding-left: 30px;
		summary {
			margin-left: -30px;
			&:focus {
				background: var(--accent);
			}
		}
	}
}
#signin {padding-top: 40%;}
sup {font-size: 0.5em}

#hints {
	margin-top: 0;
	margin-left: 10px;
	border-radius: 5px;
	.searchhint {
		color: var(--mid);
		list-style: none;
		cursor: pointer;
	}
	.fullcolor {color: var(--fg)}
}
.searchresult, summary {
	border-bottom: 1px solid var(--mid);
	cursor: pointer;
	overflow: auto;
	padding: 10px;
	transition: background 0.2s;
	&:first-of-type {border-top: 1px solid var(--mid)}
	&:hover, &:focus {background: var(--accent);}
}
.searchresult {	list-style: none; }
.song {
 	img, .artwork {
		vertical-align: middle;
		border-radius: 5px;
		width: 50px;
		height: 50px;
		background: darkgrey;
	}
	div {
		width: calc(100% - 100px);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		display: inline-block;
		vertical-align: middle;
	}
	.time {
		color: var(--mid);
	}
	&[disabled] {
		opacity: 30%;
		pointer-events: none;
		cursor: not-allowed;
	}
}
.dim {opacity:50%}
ul {padding: 0px;}
#results {margin-bottom: 50px;}
#player {
	cursor: pointer;
	padding: 10px;
	position: fixed;
	overflow-y: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50px;
	background: var(--mid);
	overflow-y: hidden;
	#nowplaying {
		width: calc(100vw - 120px);
		display: inline-block;
		float: left;
	}
	#buttons {
		button {
			background: transparent;
			border-radius: 100%;
			border: none;
			color: var(--fg);
			font-size: 1.5em;
			font-weight: bolder;
			transition: background 1s;
			&:focus, &:hover {background: black; opacity: 30%;}
		}
		width: 100px;
		text-align: right;
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: right;
	}
}
.popup {
  color: #FFFFFF;
  background: rgba(0,0,0,0.8);
  opacity: 90%;
  padding: 20px;
  border-radius: 3px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// PSEUDO-ELEMENTS =================================================
::-webkit-scrollbar {width: 7px;}
::-webkit-scrollbar-track {background: transparent;}
::-webkit-scrollbar-thumb {background: #888;border-radius:5px;}
::-webkit-scrollbar-thumb:hover {background: #555;}
